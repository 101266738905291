.stories {
  z-index: 2;
  height: 100vh;
  background: #075f9b;
  position: relative;
}

.swiper-pagination1 {
  margin-bottom: 16px !important;
}

.swiper-pagination1 .swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 24px;
  background-color: #0000 !important;
  margin: 16px !important;
}

.swiper-pagination1 .swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.stories-right-mask {
  z-index: 4;
  width: 200px;
  height: 100vh;
  background: linear-gradient(270deg, #075f9b 0%, #075f9b00 100%);
  position: absolute;
  top: 0;
  right: 0;
}

.stories-icon {
  z-index: 2;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.stories .swiper {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: relative;
  padding-bottom: 56px !important;
}

.stories .swiper-slide {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stories .swiper-pagination-bullet {
  opacity: 1;
  background-color: #6e93f7;
}

.stories .swiper-pagination-bullet-active {
  background-color: #4070f4;
}

.story-content {
  width: 100%;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.story-content h5 {
  font-family: Roboto Flex;
  font-weight: 500;
}

.story-content hr {
  height: 1px;
  width: 195px;
  background: #fff3;
}

.story-content h1 {
  letter-spacing: -.06em;
  font-family: Roboto Flex;
  font-weight: 500;
}

.story-content .story-signature {
  display: flex;
}

.story-content .story-writer {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 32px;
  display: flex;
}

.story-content .story-quotes {
  height: 36px;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.story-content .story-view-case {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.story-content .story-author {
  margin: 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
}

.story-content .story-function {
  color: #ffffff80;
  margin: 5px 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.story-content .story-view-text {
  margin: 0 14px 0 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

.story-signature-left-line {
  opacity: .5;
  width: 195px;
  height: 1px;
  background: #fff;
  margin-top: 17px;
  margin-right: 22px;
}

.story-signature-right-line {
  opacity: .5;
  width: 195px;
  height: 1px;
  background: #fff;
  margin-left: 16px;
}

@media only screen and (max-width: 480px) {
  .story-img {
    width: 80px;
  }

  .swiper-pagination1 .swiper-pagination-bullet {
    width: 16px !important;
    height: 16px !important;
    margin: 8px !important;
  }

  .stories hr {
    width: 80vw !important;
  }

  .stories-right-mask, .story-signature-left-line {
    display: none;
  }

  .story-content .story-author {
    font-size: 20px;
    line-height: 36px;
  }

  .story-content h5 {
    max-width: 395px;
    margin-bottom: 27px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    line-height: 27px;
  }

  .story-content h1 {
    max-width: 380px;
    margin: 30px 0;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 26px;
    line-height: 48px;
  }

  .story-signature-right-line {
    width: 80px;
  }

  .stories {
    min-height: 800px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .story-content .story-author {
    font-size: 20px;
    line-height: 36px;
  }

  .story-signature-left-line {
    display: none;
  }

  .story-content h5 {
    width: 395px;
    margin-bottom: 27px;
    font-size: 14px;
    line-height: 27px;
  }

  .story-content h1 {
    width: 380px;
    margin: 40px 0;
    font-size: 26px;
    line-height: 48px;
  }

  .story-signature-right-line {
    width: 140px;
  }

  .stories {
    min-height: 800px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .story-signature-right-line {
    width: 195px;
  }

  .story-content h5 {
    width: 470px;
    margin-bottom: 27px;
    font-size: 16px;
    line-height: 27px;
  }

  .story-content h1 {
    width: 530px;
    margin: 40px 0;
    font-size: 36px;
    line-height: 62px;
  }

  .story-content .story-author {
    font-size: 20px;
    line-height: 36px;
  }

  .stories {
    min-height: 800px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .story-signature-right-line {
    width: 195px;
  }

  .story-content h5 {
    width: 605px;
    margin-bottom: 27px;
    font-size: 18px;
    line-height: 27px;
  }

  .story-content h1 {
    width: 780px;
    margin: 50px 0;
    font-size: 56px;
    line-height: 79px;
  }

  .story-content .story-author {
    font-size: 20px;
    line-height: 36px;
  }

  .stories {
    min-height: 937px;
  }
}

@media only screen and (min-width: 1201px) {
  .story-signature-right-line {
    width: 195px;
  }

  .story-content h5 {
    width: 605px;
    margin-bottom: 27px;
    font-size: 18px;
    line-height: 27px;
  }

  .story-content h1 {
    width: 780px;
    margin: 50px 0;
    font-size: 56px;
    line-height: 79px;
  }

  .story-content .story-author {
    font-size: 20px;
    line-height: 36px;
  }

  .stories {
    min-height: 937px;
  }
}

/*# sourceMappingURL=index.59e0e0bd.css.map */
