.stories {
    position: relative;
    z-index: 2;
    height: 100vh;
    background: #075F9B;
}

.swiper-pagination1 {
    margin-bottom: 16px !important;
}

.swiper-pagination1 .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    background-color: transparent !important;
    border-radius: 24px;
    border: 2px solid white;
    margin: 16px !important;
}

.swiper-pagination1 .swiper-pagination-bullet-active {
    background-color: white !important;
}

.stories-right-mask {
    position: absolute;
    z-index: 4;
    width: 200px;
    height: 100vh;
    top: 0;
    right: 0;
    /* background: #075F9B; */
    background: linear-gradient(270deg, rgba(7, 95, 155, 1) 0%, rgba(7, 95, 155, 0) 100%);
}

.stories-icon {
    position: absolute;
    z-index: 2;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.stories .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 56px !important;
    position: relative;
    z-index: 3;
}

.stories .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stories .swiper-pagination-bullet {
    background-color: #6e93f7;
    opacity: 1;
}

.stories .swiper-pagination-bullet-active {
    background-color: #4070f4;
}

.story-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
}

.story-content h5 {
    font-family: "Roboto Flex";
    font-weight: 500;
}

.story-content hr {
    height: 1px;
    width: 195px;
    background: #FFFFFF33;
}

.story-content h1 {
    font-family: "Roboto Flex";
    font-weight: 500;
    letter-spacing: -0.06em;
}

.story-content .story-signature {
    display: flex;
}

.story-content .story-writer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 32px;
}

.story-content .story-quotes {
    display: flex;
    align-items: center;
    height: 36px;
    margin-bottom: 12px;
}

.story-content .story-view-case {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.story-content .story-author {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    margin: 0;
}

.story-content .story-function {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff80;
    margin: 5px 0px;
}

.story-content .story-view-text {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin: 0px 14px 0px 0px;
}


.story-signature-left-line {
    opacity: 0.5;
    background: white;
    width: 195px;
    height: 1px;
    margin-top: 17px;
    margin-right: 22px;
}

.story-signature-right-line {
    opacity: 0.5;
    background: white;
    width: 195px;
    height: 1px;
    margin-left: 16px;
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .story-img {
        width: 80px;
    }
    .swiper-pagination1 .swiper-pagination-bullet {
        width: 16px !important;
        height: 16px !important;
        margin: 8px !important;
    }

    .stories hr {
        width: 80vw !important;
    }
    .stories-right-mask {
        display: none;
    }
    .story-signature-left-line {
        display: none;
    }

    .story-content .story-author {
        font-size: 20px;
        line-height: 36px;
    }

    .story-content h5 {
        font-size: 14px;
        line-height: 27px;
        max-width: 395px;
        margin-bottom: 27px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .story-content h1 {
        font-size: 26px;
        line-height: 48px;
        max-width: 380px;
        margin: 30px 0;
        padding-left: 24px;
        padding-right: 24px;
    }

    .story-signature-right-line {
        width: 80px;
    }
    .stories {
        min-height: 800px;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .story-content .story-author {
        font-size: 20px;
        line-height: 36px;
    }

    .story-signature-left-line {
        display: none;
    }

    .story-content h5 {
        font-size: 14px;
        line-height: 27px;
        width: 395px;
        margin-bottom: 27px;
    }

    .story-content h1 {
        font-size: 26px;
        line-height: 48px;
        width: 380px;
        margin: 40px 0;
    }
    .story-signature-right-line {
        width: 140px;
    }
    .stories {
        min-height: 800px;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .story-signature-right-line {
        width: 195px;
    }
    .story-content h5 {
        font-size: 16px;
        line-height: 27px;
        width: 470px;
        margin-bottom: 27px;
    }

    .story-content h1 {
        font-size: 36px;
        line-height: 62px;
        width: 530px;
        margin: 40px 0;
    }

    .story-content .story-author {
        font-size: 20px;
        line-height: 36px;
    }
    .stories {
        min-height: 800px;
    }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
    .story-signature-right-line {
        width: 195px;
    }
    .story-content h5 {
        font-size: 18px;
        line-height: 27px;
        width: 605px;
        margin-bottom: 27px;
    }

    .story-content h1 {
        font-size: 56px;
        line-height: 79px;
        width: 780px;
        margin: 50px 0;
    }

    .story-content .story-author {
        font-size: 20px;
        line-height: 36px;
    }
    .stories {
        min-height: 937px;
    }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
    .story-signature-right-line {
        width: 195px;
    }
    .story-content h5 {
        font-size: 18px;
        line-height: 27px;
        width: 605px;
        margin-bottom: 27px;
    }

    .story-content h1 {
        font-size: 56px;
        line-height: 79px;
        width: 780px;
        margin: 50px 0;
    }

    .story-content .story-author {
        font-size: 20px;
        line-height: 36px;
    }
    .stories {
        min-height: 937px;
    }
}